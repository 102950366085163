import React, { useEffect, useState, useContext } from 'react';
import { LegalContext } from '~/providers/LegalProvider';

export default function LegalAnnotation({ annotation }) {
  const [annotationNumber, setAnnotationNumber] = useState('');
  const { disclosures, activeLegal, setActiveLegal } = useContext(LegalContext);

  useEffect(() => {
    setActiveLegal([]);
  }, []);

  useEffect(() => {
    const legalText = disclosures.find(
      (disclosure) => disclosure.handle === annotation
    )?.statement;

    if (!legalText) {
      return;
    }

    if (activeLegal.includes(legalText)) {
      setAnnotationNumber(activeLegal.indexOf(legalText) + 1);
      return;
    }

    // Using Set to get rid of duplicates
    const filteredArray = [legalText, ...new Set(activeLegal)];
    setActiveLegal(filteredArray);
    setAnnotationNumber(activeLegal.length + 1);
  }, [
    annotation,
    disclosures,
    activeLegal,
    annotationNumber,
    setAnnotationNumber,
  ]);
  return <sup>{annotationNumber}</sup>;
}
